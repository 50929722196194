.footer {
  width: 100%;
  padding: 2rem 0;
  background-color: #f2f2f2; /* Adjust the color to match your design */
  box-sizing: border-box;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100vw; /* Or the max width you prefer */
  margin: 0 auto;
  padding: 0 3rem; /* Padding on the sides */
}

.footer-content span,
.footer-content a {
  font-family: 'CabinetGrotesk', sans-serif;
  color: #0e0e0c; /* Adjust the color to match your design */
  text-decoration: none;
}

.footer-content span span {
  font-weight: 700;
}
/* If you want the 'BACK TO TOP' link to stand out */
.footer-content a {
  font-weight: bold;
}

/* Responsive behavior if needed */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    text-align: center;
  }

  .footer-content a {
    margin-top: 1rem;
  }
}
