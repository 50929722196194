.technologies {
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  min-height: 100vh;
  padding: 2rem 5%;
}

.technologies-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.technologies-body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  padding: 0 5%;
}

.tech-stack {
  flex-basis: 50%;
  text-align: left;
  margin-right: 2rem;
}

.tech-stack h2 {
  font-size: 3.5rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
}

.tech-stack h2 .normal {
  font-weight: 400; /* Regular font for "my tech" */
}

.tech-stack h2 .bold {
  font-weight: 700; /* Bold font for "stack." */
}

.tech-stack h2 .space {
  display: inline-block;
  width: 0.5rem; /* Add some space between words */
}

.tech-stack p {
  font-size: 1.5rem;
  line-height: 1.8;
  margin-bottom: 2rem;
}

.tech-list-container {
  flex-basis: 50%;
}

.tech-section {
  margin-bottom: 2rem;
}

.tech-section ul {
  list-style-type: none;
  padding: 0;
  font-size: 2.5rem;
  line-height: 1.5;
  color: #aeae9d;
}

.tech-section ul li {
  font-weight: 700;
}

@media (max-width: 768px) {
  .technologies-body {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .tech-stack {
    margin-right: 0;
    text-align: center;
  }

  .tech-list-container {
    text-align: center;
  }
}
