@import './fonts/fonts.css';
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@media (min-width: 768px) {
  /* Adjust this for the breakpoint you desire */
  .App {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
