.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  min-height: 150vh; /* 1.5 times the height of the viewport */
  padding: 2rem 0; /* Add padding on the top and bottom as needed */
  /* Any additional styling for the about section */
  background-color: #f2f2f2;
}

.about-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-body {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;
}

.profile-placeholder {
  width: 700px; /* Adjust as needed */
  height: 1100px; /* Adjust as needed */
  background-color: #fff; /* Adjust as needed */
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.introduction {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center the content vertically */
  max-width: 600px; /* Adjust the width as necessary */
  font-size: 1.25rem; /* Increased font size */
  line-height: 1.8; /* Adjust line height for readability */
  text-align: left; /* Ensure text is left-aligned */
}

.introduction p {
  margin-bottom: 1.5rem; /* Vertical space between sections */
  text-align: left; /* Ensure paragraphs are left-aligned */
}

.introduction h3 {
  font-size: 3.5rem; /* Increase font size for the header */
  margin-bottom: 2rem; /* Space between the header and the introduction */
  text-align: center; /* Keep the header centered */
}

.introduction p:first-of-type {
  font-size: 1.25rem; /* Keep the font size consistent */
}

.introduction p:nth-of-type(2) .introduction p {
  margin-bottom: 1.5rem; /* Vertical space between sections */
}

.introduction h3 {
  font-size: 3.5rem; /* Increase font size for the header */
  margin-bottom: 2rem; /* Space between the header and the introduction */
}
.introduction p:first-of-type {
  font-size: 1.25rem; /* Larger font size for the first section */
}

.introduction p:nth-of-type(2) {
  font-size: 1.25rem; /* Larger font size for the second section */
}

.introduction p:last-of-type {
  font-size: 1.25rem; /* Larger font size for the third section */
}

/* Responsive styles */
@media (max-width: 768px) {
  .about-body {
    flex-direction: column;
    padding: 0 5%; /* Less padding on smaller screens */
  }

  .profile-placeholder,
  .intro {
    /* Center the content on smaller screens */
    align-self: center;
    width: 80%; /* Adjust for smaller screens */
    margin-bottom: 2rem; /* Add space between the elements */
  }

  .intro {
    max-width: 100%; /* Allow the intro to take up more width on smaller screens */
  }
}
