.main-content {
  width: 100vw; /* Sets the width to be 100% of the viewport width */
  max-width: 100%; /* Prevents any overflow */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Make sure the landing section fills the entire viewport height */
  padding-top: 61.25px; /* Add padding to account for the fixed header, adjust based on header's actual height */
  text-align: center;
  background-color: #f2f2f2;
  position: relative; /* For positioning scroll indicator */
  background-image: none;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; /* Do not repeat the image */
}

.main-content::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50vw; /* Set the initial size of the image */
  max-width: 700px; /* Maximum width of the image */
  padding-top: calc(
    50vw * (800 / 700)
  ); /* This maintains the aspect ratio based on the width */
  max-height: 800px; /* Maximum height of the image */
  transform: translate(-50%, -50%) scale(1.1); /* Center the pseudo-element and set initial scale */
  background-image: url('/public/background.png');
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 0;
  animation: shrinkIntoPlace 1s ease-out forwards;
}

.main-content h1:nth-of-type(1),
h1:nth-of-type(3) {
  font-size: 6rem; /* Adjust based on your design */
  font-weight: 900;
  z-index: 2; /* Ensure text is above the background image */
  color: #0e0e0c; /* Example color - change as needed */
  letter-spacing: -0.3rem;
  position: relative;
}

.main-content h1:nth-of-type(2) {
  font-size: 8rem;
  font-weight: 900;
  z-index: 2;
  color: #fff;
  -webkit-text-stroke-width: 0.4rem;
  -webkit-text-stroke-color: #0e0e0c;
  letter-spacing: -0.4rem;
  position: relative;
}

.main-content span {
  font-size: 7rem;
  font-weight: 900;
  z-index: 2; /* Ensure text is above the background image */
  color: #0e0e0c; /* Example color - change as needed */
  letter-spacing: -0.4rem;
}

.main-content h1,
.main-content span {
  opacity: 0;
  animation: slideUpAndAppear 1.2s ease-out 0.7s forwards; /* Add a 1s delay here */
}
.main-content .scroll-indicator {
  font-family: 'CabinetGrotesk', sans-serif;
  position: absolute;
  bottom: 15%;
  right: 5%;
  font-size: 1.3rem;
  transform: rotate(90deg); /* Rotate counter clockwise 90 degrees */
  transform-origin: right bottom; /* Pivot from the right bottom corner */
  opacity: 0; /* Start with the scroll indicator invisible */
  animation: fadeInAndBounce 3s ease-in-out 1s forwards; /* Start after 1 second and run the animation */
}
/* Responsive design */
@media (max-width: 768px) {
  .main-content {
    height: auto; /* Allows content to determine the height */
    padding-top: 20px; /* Reduced padding on smaller screens */
    padding-bottom: 20px; /* Additional padding at the bottom for scroll indicator */
  }

  .main-content h1:nth-of-type(1),
  h1:nth-of-type(3) {
    font-size: 3.5rem;
  }

  .main-content h1:nth-of-type(2) {
    font-size: 4.5rem;
    -webkit-text-stroke-width: 0.2rem;
  }

  .main-content span {
    font-size: 4rem;
  }

  .main-content::before {
    width: 50vw; /* Smaller percentage for smaller screens */
    padding-top: calc(
      50vw * (800 / 700)
    ); /* Adjusted aspect ratio based on the new width */
    max-width: 400px; /* Smaller max-width for smaller screens */
    max-height: 450px; /* Adjusted max-height to maintain aspect ratio */
  }

  .main-content .scroll-indicator {
    bottom: 20%; /* Adjust position of the scroll indicator */
    right: 7.5%; /* Keep it at the bottom right */
    font-size: 1rem; /* Smaller font size for the scroll text */
  }
}

/* Further adjustments for very small screens */
@media (max-width: 480px) {
  .main-content h1:nth-of-type(1),
  h1:nth-of-type(3) {
    font-size: 2.6rem;
    letter-spacing: -0.2rem;
  }

  .main-content h1:nth-of-type(2) {
    font-size: 3.2rem;
    -webkit-text-stroke-width: 0.2rem;
  }

  .main-content span {
    font-size: 2.9rem;
    letter-spacing: -0.1rem;
  }

  .main-content::before {
    width: 80vw; /* Even smaller percentage for very small screens */
    padding-top: calc(
      80vw * (800 / 700)
    ); /* Adjusted aspect ratio based on the new width */
    max-width: 350px; /* Even smaller max-width for very small screens */
    max-height: 390px; /* Adjusted max-height to maintain aspect ratio */
  }

  .main-content .scroll-indicator {
    font-size: 0.8rem; /* Even smaller font size for the scroll text */
    bottom: 12.5%; /* Closer to the bottom edge */
    right: 7.5%;
  }
}

@keyframes shrinkIntoPlace {
  from {
    transform: translate(-50%, -50%) scale(1.1);
  }
  to {
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes slideUpAndAppear {
  from {
    transform: translateY(3rem);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeInAndBounce {
  0% {
    opacity: 0;
    transform: translateX(0) rotate(90deg);
  }
  10% {
    opacity: 0.7;
    transform: translateX(0) rotate(90deg);
  }
  30% {
    transform: translateY(4rem) rotate(90deg);
  }
  50% {
    transform: translateY(0) rotate(90deg);
  }
  70% {
    transform: translateY(4rem) rotate(90deg);
  }
  90% {
    transform: translateY(0) rotate(90deg);
  }
  100% {
    opacity: 0.7;
    transform: translateY(0) rotate(90deg);
  }
}
