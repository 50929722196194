/* CABINET GROTESK */

@font-face {
  font-family: 'CabinetGrotesk';
  src: url('./CabinetGrotesk-Extralight.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'CabinetGrotesk';
  src: url('./CabinetGrotesk-Thin.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'CabinetGrotesk';
  src: url('./CabinetGrotesk-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'CabinetGrotesk';
  src: url('./CabinetGrotesk-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'CabinetGrotesk';
  src: url('./CabinetGrotesk-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'CabinetGrotesk';
  src: url('./CabinetGrotesk-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'CabinetGrotesk';
  src: url('./CabinetGrotesk-Extrabold.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

/* GENERAL SANS */

@font-face {
  font-family: 'GeneralSans';
  src: url('./GeneralSans-Extralight.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'GeneralSans';
  src: url('./GeneralSans-Light.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'GeneralSans';
  src: url('./GeneralSans-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'GeneralSans';
  src: url('./GeneralSans-Medium.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'GeneralSans';
  src: url('./GeneralSans-Semibold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'GeneralSans';
  src: url('./GeneralSans-Bold.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}
